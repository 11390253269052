@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  background-color: black;
}

html {
  overflow: scroll;
  font-family: "Josefin Sans", sans-serif;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.gold {
  background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  position: relative;
}

.goldbg {
  background: linear-gradient(to bottom, #cfc09f 27%, #ffecb3 40%, #3a2c0f 78%);
    -webkit-text-fill-color: black;
    color: #fff;
    position: relative;
}